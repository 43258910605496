import React from 'react';

function Home() {
    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#C60000', position: 'relative' }}>
            <h1 style={{ color: 'white' }}>Jebena App</h1>
            <h3 style={{ color: 'white', fontWeight: "300", paddingLeft: '5px', paddingRight: '5px'}}>The first and largest dating app for Ethiopians and Eritreans</h3>
            <div style={{ display: 'flex', }}>
                <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://apps.apple.com/us/app/id1497800659"
                >
                    <img src={require("./assets/appstore.png")}  style={{ height: '50px' }}/>
                </a>
                <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://play.google.com/store/apps/details?id=app.jebena.jb"
                >
                    <img src={require("./assets/playstore.png")} style={{ height: '70px' }}/>
                </a>
            </div>
        </div>
    );
}

const styles = {
    logo: {
        width: 50, 
        height: 70
    },
    appstore: {
        //width: 150, 
        height: 50
    },
    playstore: {
        //width: 150, 
        height: 65
    },
};

export default Home;
