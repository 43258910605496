import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Privacy from './Privacy';
import Terms from './Terms';

function App() {
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <header style={{ padding: '10px', backgroundColor: '#C60000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Link to="/" style={{ color: 'white', fontSize: '24px', textDecoration: 'none' }}>
          <img src={require("./assets/jebena_logo_white.png")} style={styles.logo}  alt=""/>
        </Link>
      </header>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
      <footer style={{ position: 'absolute', bottom: '0', width: '100%', backgroundColor: '#C60000', paddingBottom: '20px', paddingTop: '10px', textAlign: 'center' }}>
        <Link to="/privacy" style={{ marginRight: '10px', color: 'white' }}>Privacy</Link>
        <Link to="/terms" style={{ color: 'white' }}>Terms</Link>
      </footer>
    </div>
  );
}

const styles = {
  pageContainer: { 
      position: "absolute",
      backgroundColor: "#C60000",
      height: "100%", 
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-around"
  },
  welcomeMsg: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
  },
  welcomeMsgText: {
      fontSize: 18,
      color: "white"
  },
  logo: {
      width: 30, 
      height: 40
  },
  appstore: {
      width: 150, 
      height: 50
  },
  title: {
      color: "white",
      marginLeft: 20
  }
};

export default App;
