import React from 'react';

function Privacy() {
  return (
    <div style={{ padding: '20px', backgroundColor: 'white', color: 'black' }}>
      <h1>Privacy Policy</h1>
      <p>Updated on: August 17, 2023</p>
<p><strong>INTRODUCTION</strong></p>
<p>Thank you for choosing to be part of our community at Jebena App. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at support@jebena.app</p>
<p>When you visit our services Jebena App you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy.We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our services.</p>
<p>This privacy policy applies to all information collected through our application, and/or any related services, sales, marketing or events (we refer to them collectively in this Privacy Policy as the "services").</p>
<p><strong>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us. </strong></p>
<p><strong>Table of contents</strong></p>
<p>WHAT INFORMATION DO WE COLLECT?</p>
<p>HOW DO WE USE YOUR INFORMATION?</p>
<p>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
<p>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
<p>DO WE USE GOOGLE MAPS?</p>
<p>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
<p>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>
<p>WHAT IS OUR STANCE ON THIRD-PARTY WEB APPLICATION?</p>
<p>HOW LONG DO WE KEEP YOUR INFORMATION?</p>
<p>HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
<p>DO WE COLLECT INFORMATION FROM MINORS?</p>
<p>WHAT ARE YOUR PRIVACY RIGHTS?</p>
<p>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
<p>DO WE MAKE UPDATES TO THIS POLICY?</p>
<p>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
<p><strong>WHAT INFORMATION DO WE COLLECT?</strong></p>
<p><strong><em>Personal information you disclose to us </em></strong></p>
<p><strong><em>In Short:</em></strong><em>We collect personal information that you provide to us such as name, contact information, location, and security data, payment information, and social media login data.</em></p>
<p>We collect personal information that you voluntarily provide to us when [registering] expressing an interest in obtaining information about us or our products and services when participating in activities on the application [(such as posting messages in our online forums or entering competitions, contests or giveaways)] or otherwise contacting us.</p>
<p>The personal information that we collect depends on the context of your interactions with us and the application, the choices you make and the products and features you use. The personal information we COLLECT can include the following:</p>
<p><strong>Name and Contact Data.</strong> We collect your first and last name, email, location, and other similar contact data.</p>
<p><strong>Social Media Login Data</strong>. We utilize your Facebook or google for authentication purposes. If you choose to register in this way, we will collect the Information described in the section called "Social Logins". All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>
<p><strong><em>Information automatically collected</em></strong></p>
<p><strong><em>In Short:</em></strong><em> Some information &ndash; such as IP address and/or browser and device characteristics &ndash; is collected automatically when you visit our application.</em></p>
<p>We automatically collect certain information when you visit, use or navigate the application. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser, and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Site and other technical information. This information is primarily needed to maintain the security and operation of our application, and for our internal analytics and reporting purposes.</p>
<p><br /><br /></p>
<p><strong><em>Information collected through our Apps</em></strong></p>
<p><strong><em>In Short:</em></strong><em> We may collect information regarding your geo-location, mobile device, push notifications, and Facebook permissions when you use our apps.</em></p>
<p>If you use our Apps, we may also collect the following information:</p>
<p><em>Geo-Location Information.</em>We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our mobile application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device&rsquo;s settings.</p>
<p><em>Mobile Device Access.</em>We may request access or permission to certain features from your mobile device, including your mobile device&rsquo;s [Bluetooth, calendar, camera, contacts, microphone,&nbsp;reminders, sensors, SMS messages, social media accounts, storage,] and other features. If you wish to change our access or permissions, you may do so in your device&rsquo;s settings.</p>
<p><em>Mobile Device Data.</em>We may automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address.</p>
<p><em>Push Notifications. </em>We may request to send you push notifications regarding your account or the mobile application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device&rsquo;s settings.]</p>
<p>[Facebook Permissions. We by default access your<a href="https://www.facebook.com/about/privacy/">Facebook</a> basic account information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. For more information regarding Facebook permissions, refer to the<a href="https://developers.facebook.com/docs/facebook-login/permissions">Facebook Permissions Reference</a>page.]</p>
<p><strong><em>Information collected from other Sources</em></strong></p>
<p><strong><em>In Short:</em></strong><em> We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</em></p>
<p>We may obtain information about you from other sources, such as public databases, joint marketing partners, social media platforms (such as Facebook), as well as from other third parties. Examples of the information we receive from other sources include: social media profile information (your name, gender, birthday, email, current city, state and country, user identification numbers for your contacts, profile picture URL and any other information that you choose to make public); marketing leads and search results and links, including paid listings (such as sponsored links).</p>
<p><strong>HOW DO WE USE YOUR INFORMATION?</strong></p>
<p><strong><em>In Short:</em></strong><em> We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em></p>
<p>We use personal information collected via our application for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
<p>We use the information we collect or receive:</p>
<p><strong>To facilitate account creation and logon process [</strong>with your Consent<strong>]</strong>. If you choose to link your account with us to a third party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process. See the section below headed "Social Logins" for further information.</p>
<p><strong>To send you marketing and promotional communications</strong> [for Business Purposes and/or with your Consent]. We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the "Your Privacy Rights" below).</p>
<p><strong>To send administrative information to you</strong> [for Business Purposes, Legal Reasons and/or possibly Contractual]. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</p>
<p><strong>Fulfill and manage your orders</strong> [for Contractual reasons]. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the application.</p>
<p><strong>To post testimonials</strong> [with your Consent]. We post testimonials on our application that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, please contact us at support@jebena.app and be sure to include your name, testimonial location, and contact information.</p>
<p><strong>Deliver targeted advertising to you </strong>[for our Business Purposes and/or with your Consent]. We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.&nbsp;</p>
<p><strong>Administer prize draws and competitions </strong>[for our Business Purposes and/or with your Consent]. We may use your information to administer prize draws and competitions when you elect to participate in competitions.</p>
<p><strong>Request Feedback </strong>[for our Business Purposes and/or with your Consent]. We may use your information to request feedback and to contact you about your use of our application.</p>
<p><strong>To protect our application </strong>[for Business Purposes and/or Legal Reasons]. We may use your information as part of our efforts to keep our application safe and secure (for example, for fraud monitoring and prevention).</p>
<p><strong>To enable user-to-user communications </strong>[with your consent]. We may use your information in order to enable user-to-user communications with each user's consent.</p>
<p><strong>To enforce our terms, conditions and policies</strong> [for Business Purposes, Legal Reasons and/or possibly Contractual].</p>
<p><strong>To respond to legal requests and prevent harm </strong>[for Legal Reasons]. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</p>
<p><strong>For other Business Purposes</strong>. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our application, products, services, marketing and your experience.</p>
<p><strong>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong></p>
<p><strong><em>In Short:</em></strong><em> We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.</em></p>
<p>We only share and disclose your information in the following situations:</p>
<p><strong><em>Compliance with Laws</em></strong><em>. We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</em></p>
<p><strong>Vital Interests and Legal Rights</strong>. We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>
<p><strong>Vendors, Consultants and Other Third-Party Service Providers</strong>. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the application, which will enable them to collect data about how you interact with the application over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</p>
<p><strong>Business Transfers</strong>. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
<p><strong><em>Third-Party Advertisers</em></strong><em>. We may use third-party advertising companies to serve ads when you visit the application. These companies may use information about your visits to our applications that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you. [See our Cookie Policy [Hyperlink] for further information]</em></p>
<p><strong><em>Affiliates. </em></strong><em>We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</em></p>
<p><strong><em>Business Partners. </em></strong><em>We may share your information with our business partners to offer you certain products, services or promotions.</em></p>
<p><strong><em>With your Consent. </em></strong><em>We may disclose your personal information for any other purpose with your consent.</em></p>
<p><strong><em>Other Users. </em></strong><em>When you share personal information (for example, by posting comments, contributions or other content to the application) or otherwise interact with public areas of the Site [or App], such personal information may be viewed by all users and may be publicly distributed outside the Site [and our App] in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our application, and view your profile.</em></p>
<p><strong><em>Offer Wall. </em></strong><em>Our Apps may display a third-party hosted &ldquo;offer wall.&rdquo; Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for acceptance and completion of an advertisement offer. Such an offer wall may appear in our mobile application and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will leave our mobile application. A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account.] </em></p>
<p><strong>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></p>
<p>We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels) to recognize you and/or your device(s). You may read our cookie policy for more information on why we use them (such as authenticating you, remembering your preferences and settings, analyzing site traffic and trends, delivering and measuring the effectiveness of advertising campaigns, allowing you to use social features) and how you can better control their use, through your browser settings and other tools.</p>
<p>Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a &ldquo;Do Not Track&rdquo; (&ldquo;<strong>DNT</strong>&rdquo;) feature that tells a website that a user does not want to have his or her online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser can block that website from collecting certain information about the browser&rsquo;s user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many businesses, including Jebena App, do not currently respond to DNT signals.</p>
<p><br /><br /></p>
<p><strong>Introduction</strong></p>
<p>Jebena App is committed to protecting your privacy. We aim to provide correct, industry-leading products and services so that you can focus on making connections.&nbsp;</p>
<p>This Cookie Policy explains what cookies are, what types of cookies are placed on your device when you visit our website and how we use them.</p>
<p>This Cookie Policy does not address how we deal with your personal information generally. To learn more about how we process your personal information, please see the section on our Privacy Policy.</p>
<p><strong>What are cookies?</strong></p>
<p>Cookies are small text files that are sent to or accessed from your web browser or your device&rsquo;s memory. A cookie typically contains the name of the domain (internet location) from which the cookie originated, the &ldquo;lifetime&rdquo; of the cookie (i.e., when it expires) and a randomly generated unique number or similar identifier. A cookie also may contain information about your device, such as user settings, browsing history and activities conducted while using our services.</p>
<p><strong>Are there different types of cookies?</strong></p>
<p><em>First-party and third-party cookies</em></p>
<p>There are first-party cookies and third-party cookies. First-party cookies are placed on your device directly by us. For example, we use first-party cookies to adapt our website to your browser&rsquo;s language preferences and to better understand your use of our website. Third-party cookies are placed on your device by our partners and service providers. For example, we use third-party cookies to measure user numbers on our website or to enable you to share content with others across social media platforms.</p>
<p><em>Session and persistent cookies</em></p>
<p>There are session cookies and persistent cookies. Session cookies only last until you close your browser. We use session cookies for a variety of reasons, including to learn more about your use of our website during one single browser session and to help you to use our website more efficiently. Persistent cookies have a longer lifespan and aren't automatically deleted when you close your browser. These types of cookies are primarily used to help you quickly sign-in to our website again and for analytical purposes.</p>
<p><strong>What about other tracking technologies, like web beacons?</strong></p>
<p>Other technologies such as web beacons (also calls pixel tags or clear gifs), tracking URLs or software development kits (SDKs) are used for similar purposes. Web beacons are tiny graphics files that contain a unique identifier that enable us to recognise when someone has visited our service or opened an e-mail that we have sent them. Tracking URLs are custom generated links that help us understand where the traffic to our webpages comes from. SDKs are small pieces of code included in apps, which function like cookies and web beacons.</p>
<p>For simplicity, we also refer to these technologies as &ldquo;cookies&rdquo; in this Cookie Policy.</p>
<p><strong>What do we use cookies for?</strong></p>
<p>Like most providers of online services, we use cookies to provide, secure and improve our services, including by remembering your preferences, recognizing you when you visit our website and personalizing and tailoring ads to your interests. To accomplish these purposes, we also may link information from cookies with other personal information we hold about you.</p>
<p>When you visit our website, some or all of the following types of cookies may be set on your device.</p>
<table>
<tbody>
<tr>
<td>
<p><strong>Cookie type</strong></p>
</td>
<td>
<p><strong>Description</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>Essential website cookies</strong></p>
</td>
<td>
<p>These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.</p>
</td>
</tr>
<tr>
<td>
<p><strong>Analytics cookies</strong></p>
</td>
<td>
<p>These cookies help us understand how our website is being used, how effective marketing campaigns are, and help us customize and improve our websites for you.</p>
</td>
</tr>
<tr>
<td>
<p><strong>Advertising cookies</strong></p>
</td>
<td>
<p>These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, selecting advertisements that are based on your interests and measuring the number of ads displayed and their performance, such as how many people clicked on a given ad.</p>
</td>
</tr>
<tr>
<td>
<p><strong>Social networking cookies</strong></p>
</td>
<td>
<p>These cookies are used to enable you to share pages and content that you find interesting on our website through third-party social networking and other websites. These cookies may also be used for advertising purposes too.</p>
</td>
</tr>
</tbody>
</table>
<p><strong>How can you control cookies?</strong></p>
<p>There are several cookie management options available to you. Please note that changes you make to your cookie preferences may make browsing our website a less satisfying experience. In some cases, you may even find yourself unable to use all or part of our site.</p>
<p><em>Browser and devices controls</em></p>
<p>Some web browsers provide settings that allow you to control or reject cookies or to alert you when a cookie is placed on your computer. The procedure for managing cookies is slightly different for each internet browser. You can check the specific steps in your particular browser help menu.</p>
<p>You also may be able to reset device identifiers by activating the appropriate setting on your mobile device. The procedure for managing device identifiers is slightly different for each device. You can check the specific steps in the help or settings menu of your particular device.</p>
<p><em>Interest-based advertising tools</em></p>
<p>You can opt out of seeing online interest-based advertising from participating companies through the<a href="http://optout.aboutads.info/?c=2#!/">Digital Advertising Alliance</a>, the <a href="http://www.youronlinechoices.eu/">Interactive Digital Advertising Alliance</a> or <a href="http://youradchoices.com/appchoices">Appchoices</a> (apps only).</p>
<p>Opting out does not mean you will not see advertising - it means you won&rsquo;t see personalized advertising from the companies that participate in the opt-out programs. Also, if you delete cookies on your device after you opted out, you will need to opt-out again.</p>
<p><strong>Social Cookies</strong></p>
<p>To allow you to share content on social media, some features of this website use social media plug-ins (e.g., Twitter&trade; &ldquo;Share to Twitter&rdquo; or LinkedIn&trade; &ldquo;in&rdquo; buttons). Depending on your social media account settings, we automatically receive information from the social media platform when you use the corresponding button on our website.</p>
<p>To learn more about social media cookies, we suggest you refer to your social media platform&rsquo;s cookie policy and privacy policy.</p>
<p><strong>Adobe Flash Player&trade; Flash cookies</strong></p>
<p>Adobe Flash Player&trade; is an application for viewing and interacting with dynamic content using the Flash platform. Flash (and similar applications) use a technology akin to cookies to memorize parameters, preferences and uses of this content. However, Adobe Flash Player manages this information and your choices via an interface separate from that supplied by your browser.</p>
<p>If your terminal is likely to display content developed using the Flash platform, we suggest you access your Flash cookie management tools directly via<a href="https://www.adobe.com/">https://www.adobe.com</a>.</p>
<p><strong>Google&trade; Cookies</strong></p>
<p><em>Stuff Google Wants to Make Sure You Know about Google&rsquo;s Data Collection Technology</em></p>
<p><em>Google&trade; Maps API Cookies</em></p>
<p>Some features of our website and some Jebena App services may rely on the use of Google&trade; Maps API Cookies. Such cookies will be stored on your device.</p>
<p>When browsing this website and using the services relying on Google&trade; Maps API cookies, you consent to the storage, collection of such cookies on your device and to the access, usage and sharing by Google of the data collected thereby.</p>
<p>Google&trade; manages the information and your choices pertaining to Google&trade; Maps API Cookies via an interface separate from that supplied by your browser. For more information, please see <a href="https://www.google.com/policies/technologies/cookies/">https://www.google.com/policies/technologies/cookies/</a>.</p>
<p><em>Google Analytics</em></p>
<p>We use Google Analytics, which is a Google service that uses cookies and other data collection technologies to collect information about your use of the website and services in order to report website trends.</p>
<p><br /><br /><strong>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></p>
<p><strong><em>In Short:</em></strong><em> If you choose to register or log in to our application using a social media account, we may have access to certain information about you.</em></p>
<p>Our application offers you the ability to register and login using your third party social media account details (like your Facebook or Google logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, e-mail address, friends list, profile picture as well as other information you choose to make public. [If you login using Facebook, we may also request access to other permissions related to your account, such as friends, check-ins, and likes, and you may choose to grant or deny us access to each individual permission.]</p>
<p>We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the application. Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you review their privacy policy to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their application and apps.</p>
<p><strong>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </strong></p>
<p><strong><em>In Short: </em></strong><em>We may transfer, store, and process your information in countries other than your own.</em><br /><br />Our servers are located in Google Cloud location nam5 (us-central). If you are accessing our application from outside Google Cloud location nam5 (us-central), please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information. If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.</p>
<p>In particular Jebena Tech, Inc. (Jebena App) complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union to the United States and has certified its compliance with it. As such, we&rsquo;re committed to subjecting all personal information received from European Union (EU) member countries, in reliance on the Privacy Shield Framework, to the Framework&rsquo;s applicable Principles. To learn more about the Privacy Shield Framework, visit the<a href="https://www.privacyshield.gov/list">U.S. Department of Commerce&rsquo;s Privacy Shield List</a>.</p>
<p>Jebena Tech, Inc. (Jebena App) is responsible for the processing of personal information it receives, under the Privacy Shield Framework, and subsequently transfers to a third party acting as an agent on its behalf.</p>
<p>With respect to personal information received or transferred pursuant to the Privacy Shield Framework, Jebena Tech, Inc. (Jebena App) is subject to the regulatory enforcement powers of the U.S. FTC. In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
<p><strong>WHAT IS OUR STANCE ON THIRD-PARTY application?</strong></p>
<p><strong><em>In Short:</em></strong><em> We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our application.</em></p>
<p>The application may contain advertisements from third parties that are not affiliated with us and which may link to other web application, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other web application, services or applications that may be linked to or from the application. You should review the policies of such third parties and contact them directly to respond to your questions.</p>
<p><strong>HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
<p><strong><em>In Short:</em></strong><em> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</em></p>
<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information longer than 30 days after the termination of your account.&nbsp;</p>
<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
<p><br /><br /></p>
<p><strong>HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></p>
<p><strong><em>In Short:</em></strong><em> We aim to protect your personal information through a system of organizational and technical security measures.</em></p>
<p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our application is at your own risk. You should only access the services within a secure environment.</p>
<p><strong>DO WE COLLECT INFORMATION FROM MINORS?</strong></p>
<p><strong><em>In Short:</em></strong><em> We do not knowingly collect data from or market to children under 18 years of age.</em></p>
<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the application, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Site [and App]. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at support@jebena.app</p>
<p><strong>WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
<p><strong><em>In Short:</em></strong><em> [In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information.] You may review, change, or terminate your account at any time.</em></p>
<p>[In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please contact us at support@jebena.app</p>
<p><br /><br /></p>
<p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</p>
<p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you can contact us at support@jebena.app. You also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm] ]</p>
<p><strong><em>Account Information</em></strong></p>
<p>You may at any time review or change the information in your account or terminate your account by:</p>
<p>Logging into your settings and deactivating your account</p>
<p>Upon your request to deactivate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.]</p>
<p>To delete your account, go to <strong>Profile > Settings > Privacy > Delete Account</strong> You will be asked to confirm the request.  Upon confirmation, all of your accounts data is permanently deleted.</p>
<p><strong>Opting out of email marketing</strong>: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list &ndash; however, we will still need to send you service-related emails that are necessary for the administration and use of your account.</p>
<p><strong>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></p>
<p><strong><em>In Short:</em></strong><em> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></p>
<p>California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo; law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
<p>support@jebena.app</p>
<p><strong>DO WE MAKE UPDATES TO THIS POLICY?</strong></p>
<p><strong><em>In Short:</em></strong><em> Yes, we will update this policy as necessary to stay compliant with relevant laws.</em></p>
<p>We may update this privacy policy from time to time. The updated version will be indicated by an updated &ldquo;Revised&rdquo; date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
<p><strong>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</strong></p>
<p>If you have questions or comments about this policy, email us support@jebena.app</p>
<p>Jebena App</p>
<p>Data officer: Henok WeldeMicael</p>
<p>support@jebena.app</p>
<p>[If you are a resident in the European Economic Area, the "data controller" of your personal information is Jebena App. Jebena App, has appointed Henok WeldeMIcael to be its representative in the EEA. You can contact them directly regarding the processing of your information by emailing&nbsp;support@jebena.app</p>
<p>If you have any further questions or comments about us or our policies, email us at:&nbsp;support@jebena.app</p>
<p><br /><br /><br /><br /></p>
    </div>
  );
}

export default Privacy;
